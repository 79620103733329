<template>
    <div :class="{'selection-preview': true, 'has--selection': true, 'is--light': light}">
        <b-row>
            <b-col cols="12" v-if="!hideTitle">
                <span class="h2 preview-title">{{ $tc('selectionPreview.titleSelection') }}</span>
            </b-col>
            <b-col cols="12" v-for="(item, index) in selection" :key="'selectionPreviewItem-' + index">

                <div class="selection-preview-item">
                    <b-row>
                        <b-col cols="12" :sm="light ? 2 : 4">
                            <b-img fluid :src="item.product.image ? item.product.image : imageFallback"/>
                        </b-col>
                        <b-col cols="12" :sm="light ? 10 : 8" class="config-desc">
                            <div class="h3">{{ item.product.title }}</div>
                            <p>{{ item.product.description }}</p>
                            <SubOptionForm v-if="showOptionEditor"
                                           :product-id="item.product.id"
                                           v-model="item.value.options"
                                           :perform-actions="item.value.selected"
                            />
                            <template v-for="(option, oindex) in item.value.options">
                                <b-row v-for="(value, vindex) in option.value" :key="'soption-' + oindex + '-' + vindex">
                                    <b-col cols="12">
                                       {{ getOptionValue(item.product.id, option.id, value) }}
                                    </b-col>
                                </b-row>
                            </template>
                            <b-row>
                                <b-col>
                                    <span class="price h4">{{price(item)}}</span>
                                </b-col>
                                <b-col class="d-flex justify-content-end" v-if="!hideRemoveBtn">
                                    <div href="#" class="item-remove" @click="unselect(item.product.id)">
                                        {{ $tc('selectionPreview.removeItem') }}
                                        <b-icon-x font-scale="2"/>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import SubOptionForm from "./select-box/SubOptionForm";
    export default {
        name: "SelectionPreview",
        components: {SubOptionForm},
        props: {
            productId: String,
            value: {
                type: Array,
                defaultValue: []
            },
            imageFallback: String,
            hideTitle: Boolean,
            hideRemoveBtn: Boolean,
            showOptionEditor: Boolean,
            light: Boolean
        },
        methods: {
            price(item) {
                let price = this.$getDinero(0);
                let hidePrice = true;
                const product = this.$store.getters.getProduct(item.value.id);
                let showSumPrefix = 'pricePrefix' in product && product.pricePrefix.length > 0;
                if ('price' in product && product.price > 0) {
                    price = price.add(this.$getDinero(product.price));
                    hidePrice = false;
                } else if (item.value.options.length === 0) {
                    return product.pricePrefix;
                }
                item.value.options.filter(o => o.value.length > 0).forEach(optionData => {
                    optionData.value.forEach(optionValueData => {
                        const optionValue = this.$store.getters.getProductOptionValue(item.value.id, optionData.id, optionValueData);
                        if (optionValue && 'price' in optionValue && optionValue.price > 0) {
                            price = price.add(this.$getDinero(optionValue.price));
                            hidePrice = false;
                        }
                        if (optionValue && 'pricePrefix' in optionValue && optionValue.pricePrefix) {
                            showSumPrefix = true;
                        }
                    });
                });
                if (item.value.options.length === 0) {
                    let productPricePrefix = '';
                    if ('pricePrefix' in product) {
                        productPricePrefix = product.pricePrefix;
                    }
                    return productPricePrefix + ' ' + this.$dineroFormat(price);
                }
                if (showSumPrefix) {
                    return hidePrice ? '' : this.$tc('global.priceSumPrefix') + this.$dineroFormat(price);
                } else {
                    return hidePrice ? '' :  this.$dineroFormat(price);
                }

            },
            getItemPrice(item) {
                const pricePrefix = item.pricePrefix ? item.pricePrefix : '';
                return item.price >= 0 ? pricePrefix + this.$formatCurrency(item.price) : pricePrefix;
            },
            getOptionPrice(productId, optionId, value) {
                const optionValue = this.$store.getters.getProductOptionValue(productId, optionId, value);
                if (!optionValue) {
                    return '';
                }
                return this.getItemPrice(optionValue);
            },
            getOptionValue(productId, optionId, value) {
                const option = this.$store.getters.getProductOption(productId, optionId);
                const optionValue = this.$store.getters.getProductOptionValue(productId, optionId, value);
                if (!optionValue) {
                    return option.title + ': ' + value;
                }

                return optionValue.label;
            },
            unselect(productId) {
                const index = this.valueModel.findIndex(product => product.id === productId);
                this.$set(this.valueModel[index], 'selected', false);
                this.$emit('unselect', productId);
            }
        },
        computed: {
            valueModel: {
                get() { return this.value },
                set (value) { this.$emit('input', value); }
            },
            selection() {
                return this.valueModel.filter(p => p.selected).map((value) => {
                    return {
                        value: value,
                        product: this.$store.getters.getProduct(value.id)
                    };
                });
            }
        },
    }
</script>

<style scoped>

</style>