import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/axios';
import './plugins/bootstrap-vue';
import './plugins/dinero';
import store from './plugins/vuex';
import App from './App.vue';
import i18n from './plugins/i18n';

import 'source-sans-pro/source-sans-pro.css';

import './scss/app.scss';
Vue.config.productionTip = false;

if (window.apiUrl === '{{ full_url_for("index") }}') {
  //dev mode
  window.apiUrl = '/';
}

new Vue({
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
