<template>
    <div>
        <b-btn squared size="lg" @click="modal=true" v-if="hasSelection">
            {{ $tc('configOption.btnEditSelection') }}
        </b-btn>
        <b-btn squared size="lg" variant="primary" @click="modal = true" v-else>
            {{ $tc('configOption.btnSelect') }}
        </b-btn>
        <b-modal v-model="modal" scrollable size="xl" :title="title" :hide-footer="!multiple">
            <SelectionPreview ref="preview" v-model="valueModel" v-if="hasSelection" :show-option-editor="columns === 3" />
            <b-row v-if="hasSelection">
                <b-col cols="12">
                    <div class="continue-box">

                        <b-btn squared size="lg" variant="primary" @click="modal=false">
                            {{ $tc('selectionModal.btnContinue') }}
                        </b-btn>
                        <div class="selection-info"  v-if="multiple">
                            {{ $tc('selectionModal.multiple', selectedProductCount, { count: selectedProductCount } ) }}
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <template v-for="(product, index) in valueModel">
                <b-col v-if="!product.selected" cols="12" :sm="colsSm" :lg="colsLg"  :key="'boxcol-' + index">
                    <component
                            :is="boxComponent"
                            @select="select"
                            v-model="valueModel[index]"/>
                </b-col>
                </template>
            </b-row>
            <b-row v-if="textBottom">
                <b-col class="modal-text-bottom">
                    {{ textBottom }}
                </b-col>
            </b-row>
            <template #modal-footer v-if="multiple">
                {{ $tc('selectionModal.multiple', selectedProductCount, { count: selectedProductCount } ) }}
                <b-btn squared size="lg" variant="primary" @click="modal=false">
                    {{ $tc('selectionModal.btnContinue') }}
                </b-btn>
            </template>
        </b-modal>
    </div>

</template>

<script>
    import SelectionPreview from "./SelectionPreview";
    import BoxThreeColumns from "./select-box/Box-Three-Columns";
    import BoxOneColumn from "./select-box/Box-One-Column";
    export default {
        name: "SelectionModal",
        components: {BoxThreeColumns, BoxOneColumn, SelectionPreview},
        props: {
            title: String,
            productIds: {type: Array, default: () => []},
            value: Array,
            columns: Number,
            textBottom: String,
            multiple: { type: Boolean, default: false },
        },
        computed: {
            boxComponent() {
                return this.columns === 3 ? 'BoxThreeColumns' : 'BoxOneColumn';
            },
            colsSm() {
                return this.columns === 3 ? 6 : 12;
            },
            colsLg() {
                return this.columns === 3 ? 4 : 12;
            },
            valueModel: {
                get() { return this.value; },
                set (value) { this.$emit('input', value); }
            },
            hasSelection() {
                return this.valueModel.find(i => i.selected)
            },
            selectedProductCount() {
                return this.valueModel.filter(i => i.selected).length;
            }
        },
        data() {
            return {
                modal: false,
            }
        },
        methods: {
            select(productId) {
                this.valueModel.forEach((product, index) => {
                    if (productId === product.id) {
                        this.$set(this.valueModel[index], 'selected', true);

                        // perform linked actions
                        const productObject = this.$store.getters.getProduct(productId);
                        if (productObject && 'actions' in productObject && productObject['actions'].length > 0) {
                            this.$store.dispatch('runActions', productObject['actions']);
                        }
                        product.options.forEach(option => {
                            option.value.forEach(value => {
                                const valueObject = this.$store.getters.getProductOptionValue(productId, option.id, value);
                                if (valueObject && 'actions' in valueObject && valueObject['actions'].length > 0) {
                                    this.$store.dispatch('runActions', valueObject['actions']);
                                }
                            });
                        });



                    } else if (!this.multiple) {
                        this.$set(this.valueModel[index], 'selected', false);
                    }
                });



                if (this.columns === 3 && this.$store.getters.getProduct(productId).options.length > 0) {
                    setTimeout(() => {
                        this.$refs.preview.$el.parentElement.scrollTo(0, 0);
                    }, 200);
                } else if (!this.multiple) {
                     this.modal = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>