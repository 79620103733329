<template>
    <div>
        <label>
            {{config.title}}
            <b-form-radio-group
                v-model="valueModel"
            >
                <template v-for="option in config.values">
                    <b-form-radio :value="option.id" :key="option.id" class="rounded-pill mx-1">
                        <strong v-if="option.description">{{getOptionLabel(option)}}</strong>
                        <span v-else>{{getOptionLabel(option)}}</span>
                        <p v-if="option.description">{{option.description}}</p>
                    </b-form-radio>
                </template>
            </b-form-radio-group>
        </label>
    </div>
</template>

<script>
export default {
    name: "Radio",
    props: {
        config: Object,
        value: Array
    },
    computed: {
        valueModel: {
            get() { return this.value.length > 0 ? this.value[0] : '' },
            set (value) { this.$emit('input', [value]); }
        }
    },
    watch: {
        config: {
            deep: true,
            handler() {
                this.setDefaultValue();
            }
        }
    },
    created() {
        this.setDefaultValue();
    },
    methods: {
        setDefaultValue() {
            if (this.valueModel === '' && 'defaultValue' in this.config) {
                this.valueModel = this.config.defaultValue;
            }
        },
        getOptionLabel(item) {
            const pricePrefix = item.pricePrefix ? item.pricePrefix : '';
            return item.price >= 0 ? item.label + ' ' + pricePrefix + this.$formatCurrency(item.price) : item.label + ' ' + pricePrefix
        }
    }
}
</script>

<style scoped>

</style>