<template>
    <div>
        <label>
            {{config.title}}
            <b-form-select v-model="valueModel" :options="selectOptions"></b-form-select>
        </label>
    </div>
</template>

<script>
export default {
    name: "Dropdown",
    props: {
        config: Object,
        value: Array
    },
    computed: {
        valueModel: {
            get() { return this.value.length > 0 ? this.value[0] : '' },
            set (value) { this.$emit('input', [value]); }
        },
        selectOptions() {
            return this.config.values.map((item) => {
                const pricePrefix = item.pricePrefix ? item.pricePrefix : '';
                return {
                    'value': item.id,
                    'text': item.price >= 0 ? item.label + ' ' + pricePrefix + this.$formatCurrency(item.price) : item.label + ' ' + pricePrefix
                }
            })
        }
    }
}
</script>

<style scoped>

</style>