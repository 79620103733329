<template>
    <b-container class="captcha-container">
        <b-row>
            <b-col>
                <label>
                    {{ $tc('captcha.label') }}*
                    <b-input v-model="valueModel" :aria-invalid="error" :class="{'has--error': error}"/>
                </label>
                <b-img v-if="image" :src="image" class="captcha-image"/>
            </b-col>
        </b-row>
        <b-row v-if="error" class="captcha-error">
            <b-col>
                {{ $tc('captcha.error')}}
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
export default {
    name: "Captcha",
    props: {
        image: String,
        value: String,
        error: Boolean
    },

    computed: {
        valueModel: {
            get() { return this.value },
            set (value) { this.$emit('input', value); }
        }
    }
}
</script>

<style scoped>

</style>