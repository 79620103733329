 <template>
    <fieldset>
        <b-row>
            <b-col>
                <label class="h3">{{ config.title }}</label>
            </b-col>
        </b-row>
        <b-row v-for="(item,index) in fields"
               :key="'fieldset-field-' + index">
            <b-col>
                <component
                    :is="getComponent(item.field.type)"
                    :config="item.field"
                    v-model="item.data.value"
                />
            </b-col>
        </b-row>
    </fieldset>
</template>

<script>
import Checkbox from "./element/Checkbox";
import Radio from "./element/Radio";
import Textarea from "./element/Textarea";
import Textfield from "./element/Textfield";

export default {
    name: "Fieldset",
    components: {Checkbox, Textarea, Textfield, Radio},
    props: {
        config: { type: Object, default: () => {
            return {

            };
        }},
        value: Object
    },
    computed: {
        valueModel: {
            get() { return this.value },
            set (value) { this.$emit('input', value); }
        },
        fields() {
            return this.valueModel.fields.map(fieldData => {
                return {
                    field: this.config.fields.find(f => f.id === fieldData.id),
                    data: fieldData
                }
            });
        }
    },
    methods: {
        getComponent(type) {
            switch (type) {
                case 'checkbox': return 'Checkbox';
                case 'textarea': return 'Textarea';
                case 'textfield': return 'Textfield';
                case 'radio': return 'Radio';
            }
            return '';
        }
    }
}
</script>

<style scoped>

</style>