<template>
    <b-container :class="{'container-select': true, 'has--selection': valid}">
        <span class="h2">{{ productGroup.title }}</span>
        <SelectionPreview v-if="hasSelection"
                          hide-title
                          :image-fallback="productGroup.preview.image"
                          v-model="valueModel.products"
                          @unselect="valueModel.skipped = false"
        />
        <b-row v-else>
            <b-col cols="12" md="4" v-if="productGroup.preview.image">
                <b-img :src="productGroup.preview.image" fluid v-if="productGroup.preview.image"/>
            </b-col>
            <b-col cols="12" :md="productGroup.preview.image ? 8 : 12" class="config-desc">
                <p>{{ productGroup.preview.description }}</p>
            </b-col>
        </b-row>
        <div class="productgroup--actions">
            <b-btn squared size="lg" variant="primary"
                   v-if="productGroup.skippable && !hasSelection && !valueModel.skipped"
                   @click="valueModel.skipped = true"
                   class="btn-skip"
            >
                {{ $tc('configOption.labelSkip') }}
            </b-btn>
            <SelectionModal
                v-model="valueModel.products"
                :multiple="productGroup.multiple"
                :title="productGroup.modalTitle"
                :text-bottom="productGroup.modalTextBottom"
                :product-ids="productGroup.products"
                class="selection-modal-wrap"
                :columns="productGroup.columns" />
        </div>
    </b-container>
</template>

<script>
    import SelectionModal from "./SelectionModal";
    import SelectionPreview from "./SelectionPreview";
    export default {
        name: "ConfigOption",
        components: {SelectionPreview, SelectionModal},
        props: {
            value: { type: Object, default: () => { return {}; } }
        },
        computed: {
            valid() {
                 return this.hasSelection || this.valueModel.skipped;
            },
            hasSelection() {
                return this.valueModel.products.find(i => i.selected)
            },
            valueModel: {
                get() { return this.value; },
                set (value) { this.$emit('input', value); }
            },
            productGroup() {
                return this.$store.getters.getProductGroup(this.valueModel.id);
            }
        }
    }
</script>

<style scoped>

</style>