<template>
    <b-container class="captcha-container hcaptcha-container">
        <b-row>
            <b-col class="hcaptcha-container-row">
                <vue-hcaptcha
                    :sitekey="sitekey"
                    @verify="onVerify"
                    @expired="onExpire"
                    @error="onError"
                />
            </b-col>
        </b-row>
        <b-row v-if="error" class="captcha-error">
            <b-col>
                {{ $tc('captcha.error')}}
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
export default {
    name: "Captcha",
    props: {
        value: String,
        sitekey: String,
        error: Boolean
    },
    components: {VueHcaptcha},
    computed: {
        valueModel: {
            get() { return this.value },
            set (value) { this.$emit('input', value); }
        }
    },
    methods: {
        onVerify(token) {
            this.valueModel = token;
        },
        onExpire() {
            this.valueModel = '';
        },
        onError(err) {
            this.valueModel = '';
            console.log(`Error: ${err}`);
        }
    }
}
</script>

<style scoped>

</style>