<template>
    <b-container class="container-step">
        <b-row>
            <b-col>
                <span class="h1 step-title">{{ $tc('summary.title') }}</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="h3">{{ $tc('summary.selectionSummary') }}</div>
                <p>{{ $tc('summary.selectionSummaryText') }}</p>
            </b-col>
        </b-row>
        <b-row v-for="(step,stepindex) in stepData" :key="'step' + stepindex">
            <b-col cols="12">
                <div class="h4">{{stepindex +1 }}. {{step.title}}</div>
            </b-col>
            <b-col cols="12" v-for="(productGroup,selectionsindex) in step.productGroups" :key="'step' + stepindex + '-selections-' +selectionsindex">
                <SelectionPreview v-if="!productGroup.data.skipped"
                                  hide-title
                                  :image-fallback="productGroup.preview.image"
                                  v-model="productGroup.data.products"
                                  hide-remove-btn
                                  light
                />
                <div v-else class="selection-preview is--light has--selection">
                    <b-row >
                        <b-col cols="12" sm="2">
                            <b-img fluid :src="productGroup.preview.image"/>
                        </b-col>
                        <b-col cols="12" sm="10" class="config-desc">
                            <div class="h3">{{ productGroup.title }}</div>
                            <p>{{ $tc('configOption.labelSkip') }}</p>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12 total-price-row">
                <div class="h5 summary-total-price">{{$tc('summary.total') }} <strong>{{ totalPrice }}</strong></div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import SelectionPreview from "../configurator_items/SelectionPreview";
    export default {
        name: "Summary",
        components: {SelectionPreview},
        props: {
            data: Array,
            steps: Array,
            totalPrice: String,
            configurator: Object
        },
        computed: {
            selectionStepData() {
                return this.steps.filter(i => i.type === 'selection');
            },
            stepData() {
                return this.steps.filter(i => i.type === 'selection').map(step => {
                    const stepData = this.data.find(i => i.id === step.id);
                    return {...step,
                        productGroups: step.productGroups.filter(pg => !this.configurator.exclude.includes(pg.id)).map(pg => {
                            const pgData = stepData.productGroups.find(g => g.id === pg.id);
                            return {...pg,
                                'data': pgData
                            };
                        })
                    };
                });
            }
        }
    }
</script>

<style scoped>

</style>