<template>
    <b-modal v-model="modal"
             :title="$tc('consent.title')"
             :cancel-title="$tc('consent.btnRefuse')"
             :ok-title="$tc('consent.btnConsent')"
             @ok="onConsent"
             @cancel="onRefuse"
             hide-header-close
             no-close-on-esc
             no-close-on-backdrop
    >
        {{ $tc('consent.text') }}
    </b-modal>
</template>

<script>
export default {
    name: "ConsentModal",
    data() {
        return {
            modal: false
        }
    },
    methods: {
        onConsent() {
            localStorage.setItem('consent', 'true');
        },
        onRefuse() {
            localStorage.removeItem('settings');
            localStorage.removeItem('consent');

        }
    },
    created() {
        if (localStorage.getItem('consent') === null) {
            this.modal = true;
        }
    }
}
</script>

<style scoped>

</style>