<template>
    <b-container class="container-step">
        <Header :title="config.title" :total-price="totalPrice"/>
        <b-row>
            <b-col cols="12" md="4" v-if="config.image">
                <b-img :src="config.image" fluid :alt="config.imageAlt"/>
            </b-col>
            <b-col cols="12" md="8">
                <Fieldset
                    v-for="(item, index) in fieldsets"
                    :key="'form-fieldset-' + index"
                    :config="item.fieldset"
                    v-model="item.data"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Fieldset from "../form/Fieldset";
    import Header from "./Header";
    export default {
        name: "Form",
        components: {Header, Fieldset},
        props: {
            config: {Object, default: () => { return {'title': '', items: []}; } },
            value: Object,
            totalPrice: String
        },
        computed: {
            valueModel: {
                get () { return this.value },
                set (value) { this.$emit('input', value) },
            },
            fieldsets() {
                return this.valueModel.fieldsets.map(fieldsetData => {
                    return {
                        fieldset: this.config.fieldsets.find(f => f.id === fieldsetData.id),
                        data: fieldsetData
                    };
                });
            }
        }
    }
</script>

<style scoped>

</style>