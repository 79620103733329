<template>
    <b-container class="step-navigation">
        <b-row class="text-right">
            <b-col>
                {{ $tc('stepNavigation.textRequiredFields') }}
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-btn squared size="lg" :disabled="!prev" variant="primary" @click="$emit('prev')">
                    <b-icon-arrow-left/> {{ $tc('stepNavigation.btnPrevious') }}
                </b-btn>
            </b-col>
            <b-col cols="6" class="container-next">
                <b-btn squared class="" size="lg" variant="primary" :disabled="(!next)" @click="$emit('next')">
                    <template v-if="last">
                        {{ $tc('stepNavigation.btnLast') }}
                    </template>
                    <template v-else>
                        {{ $tc('stepNavigation.btnNext') }}
                    </template>
                    <b-icon-arrow-right/>
                </b-btn>
                <br/>
                <p class="validation-error-txt" v-if="!next">{{ $tc('stepNavigation.validationError') }}</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "StepNavigation",
        props: {
            prev: Boolean,
            next: Boolean,
            last: Boolean
        }
    }
</script>

<style scoped>
    .container-next {
        text-align: right;
    }
    .validation-error-txt {
        max-width: 250px;
        display: inline-block;
    }
</style>