import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=243cd3a2&scoped=true&"
import script from "./Selection.vue?vue&type=script&lang=js&"
export * from "./Selection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243cd3a2",
  null
  
)

export default component.exports