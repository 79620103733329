<template>
    <b-form-group
        :label="config.label"
    >
        <b-input v-model="valueModel" :placeholder="config.placeholder"/>
    </b-form-group>
</template>

<script>
export default {
    name: "Textfield",
    props: {
        config: Object,
        value: Array
    },
    computed: {
        valueModel: {
            get() { return this.value.length > 0 ? this.value[0] : '' },
            set (value) { this.$emit('input', [value]); }
        }
    }
}
</script>

<style scoped>

</style>