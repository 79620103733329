<template>
    <b-container class="container-step">
        <Header :title="config.title" :total-price="totalPrice"/>
        <b-row

                class="config-option-row"
                v-for="(productGroup, index) in valueModel.productGroups"
                :key="'select-' + index"
            >
            <b-col cols="12">
                <ProductGroup v-model="valueModel.productGroups[index]"/>
            </b-col>

        </b-row>
    </b-container>
</template>

<script>
    import ProductGroup from "../configurator_items/ProductGroup";
    import Header from "./Header";
    export default {
        name: "Selection",
        props: {
            config: {Object, default: () => { return {'title': '', items: []}; } },
            value: Object,
            totalPrice: String
        },
        components: {Header, ProductGroup},
        computed: {
            valueModel: {
                get () { return this.value },
                set (value) { this.$emit('input', value) },
            },
            valid() {
                for (const val in this.value) {
                    if (this.value[val].length === 0) {
                        return false;
                    }
                }
                return true;
            }
        },


    }
</script>

<style scoped>

</style>