import Dinero from 'dinero.js'
import Vue from 'vue';

function getDinero(amount, currency='EUR') {
    return Dinero({ amount: amount, currency: currency });
}
function dineroFormat(dinero) {
    return dinero.setLocale('de-DE').toFormat('0,0.00') + ' € **';
}
const DineroPlugin = {};
DineroPlugin.install = function (Vue) {
    Vue.prototype.$Dinero = Dinero;
    Vue.prototype.$getDinero = getDinero;
    Vue.prototype.$dineroFormat = dineroFormat;
    Vue.prototype.$formatCurrency = (amount) => dineroFormat(getDinero(amount));
};

Vue.use(DineroPlugin);