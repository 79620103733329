<template>
    <b-row class="select-box one-column">
        <b-col cols="12">
            <span class="h5 item-title">{{ product.title }}</span>
        </b-col>
        <b-col cols="12" md="4" v-if="product.image">
            <b-img fluid :src="product.image" class="item-image" />
        </b-col>
        <b-col cols="12" :md="mdDescription" class="item-description">
            {{ product.description }}
            <p class="h5 item-price"><br/>
                {{product.pricePrefix}}<template v-if="product.price >= 0">{{ $formatCurrency(product.price) }}</template>
            </p>
        </b-col>
        <b-col cols="12" :md="mdOptions" class="item-options">
          <SubOptionForm :product-id="product.id" :perform-actions="valueModel.selected" v-model="valueModel['options']"/>
        </b-col>
        <b-col cols="12">
            <b-btn class="item-btn-select float-right" squared size="lg" variant="primary" @click="onSelectClick">
                {{ $tc('boxThreeColumns.btnSelect') }}
            </b-btn>
        </b-col>
    </b-row>
</template>

<script>
import SubOptionForm from "./SubOptionForm";
export default {
    name: "Box-One-Column",
    components: {SubOptionForm},
    props: {
        value: Object
    },
    computed: {
        valueModel: {
            get() { return this.value },
            set (value) { this.$emit('input', value); }
        },
        product() {
            return this.$store.getters.getProduct(this.valueModel.id);
        },
        mdDescription() {
            let cols = 4;
            if (!this.product.image && this.product.options.length === 0) {
                cols += 4;
            }
            if (this.product.options.length === 0) {
                cols += 4;
            }
            return cols;
        },
        mdOptions() {
            let cols = 4;
            if (!this.product.image) {
                cols += 4;
            }
            if (!this.product.description) {
                cols += 4;
            }
            return cols;
        }
    },
    methods: {
        onSelectClick() {
            this.$emit('select', this.product.id);
        }
    }
}
</script>

<style lang="scss" scoped>
    @media screen and (min-width: 768px) {
        .item-options, .item-description {
            &.col-md-8 {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                & > * {
                    width: 49%;
                }
            }
            &.col-md-12 {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                & > * {
                    width: 32%;
                }

            }
        }
    }

</style>