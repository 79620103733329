import Ajv from "ajv";

import formElementCheckbox from './form_elements/checkbox.json';
import formElementRadio from './form_elements/radio.json';
import formElementTextarea from './form_elements/textarea.json';
import formElementTextfield from './form_elements/textfield.json';

import suboptionAction from './suboptions/action.json';
import suboptionCheckbox from './suboptions/checkbox.json';
import suboptionDropdown from './suboptions/dropdown.json';
import suboptionRadio from './suboptions/radio.json';
import suboptionTextfield from './suboptions/textfield.json';

import config from './config.json';
import configurator from './configurator.json';
import formItem from './formItem.json';
import formStep from './formStep.json';
import product from './product.json';
import productGroup from './productGroup.json';
import selectionStep from './selectionStep.json';
import termCondition from './termCondition.json';

const ajv = new Ajv({schemas: [
        formElementCheckbox,
        formElementRadio,
        formElementTextarea,
        formElementTextfield,
        suboptionAction,
        suboptionCheckbox,
        suboptionDropdown,
        suboptionRadio,
        suboptionTextfield,
        config,
        product,
        configurator,
        formItem,
        formStep,
        selectionStep,
        productGroup,
        termCondition
]});

export default ajv.getSchema('./config.json');