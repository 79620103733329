<template>
    <b-container class="main-navigation-wrap">
        <b-nav class="main-navigation" align="center">
            <b-nav-item
                v-for="(item, index) in items"
                :active="currentIndex === index"
                :disabled="index > availableUntil"
                :key="'navitem-' + index"
                @click="onSelect(index)"
            >
                {{ item }}
                <b-icon-check-circle-fill v-if="stepStatus[index] === 'valid'"/>
                <b-icon-exclamation-circle-fill v-if="stepStatus[index] === 'containsSkippedItems'"/>
            </b-nav-item>
        </b-nav>
    </b-container>
</template>

<script>
    export default {
        name: "Navigation",
        props: {
            items: { type: Array, default: () => { return []; } },
            currentIndex: { type: Number, default: 0 },
            availableUntil: { type: Number, default: 0 },
            stepStatus: {type: Array, default: () => { return []; }}
        },
        methods: {
            onSelect(index) {
                if (index <= this.availableUntil) {
                    this.$emit('goto', index);
                }
            }
        }
    }
</script>

<style scoped>

</style>