<template>
    <div class="select-box three-columns" @click="onSelectClick">
        <b-img fluid :src="product.image" class="item-image" v-if="product.image"/>
        <div class="h5 item-title">{{ product.title }}</div>
        <div class="h5 item-price">{{product.pricePrefix}}<span  v-if="product.price >= 0">{{ $formatCurrency(product.price) }}</span></div>
        <b-btn class="item-btn-select" squared block size="lg" variant="primary" >
            {{ $tc('boxThreeColumns.btnSelect') }}
        </b-btn>
    </div>
</template>
<script>
    export default {
        name: "Box-Three-Columns",
        props: {
            value: Object
        },
        computed: {
            valueModel: {
                get() { return this.value },
                set (value) { this.$emit('input', value); }
            },
            product() {
                return this.$store.getters.getProduct(this.valueModel.id);
            }
        },
        methods: {
            onSelectClick() {
                this.$emit('select', this.product.id);
            }
        }
    }
</script>

<style scoped>

</style>