<template>
    <footer>
        <b-container>
            {{ $tc('footer.copyright') }}
            -
            <a :href="$tc('footer.imprintLink')" :title="$tc('footer.imprint')">{{ $tc('footer.imprint') }}</a>
            -
            <a :href="$tc('footer.privacyPolicyLink')" :title="$tc('footer.privacyPolicy')">{{ $tc('footer.privacyPolicy') }}</a>
        </b-container>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>