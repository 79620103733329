<template>
    <b-row>
        <b-col order-md="2" cols="12" md="4" lg="3" class="header-total-price">
            {{$tc('summary.total') }} <strong>{{ totalPrice }}</strong>
        </b-col>
        <b-col col md="8" lg="9">
            <span class="h1 step-title">{{ title }}</span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "Header",
    props: {
        totalPrice: {
            type: String,
            defaultValue: ''
        },
        title: {
            type: String,
            defaultValue: ''
        }
    }
}
</script>