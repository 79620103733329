<template>
    <b-container class="term-condition">
        <b-row v-for="(tc,index) in config" :key="'tc-' + index">
            <b-col>
                <h3>{{tc.title}}</h3>
                <p v-if="tc.text">{{ tc.text }}</p>
                <ul v-if="tc.files && tc.files.length > 0">
                    <li v-for="(file, findex) in tc.files" :key="'tcf-' + index + '-' + findex">
                        <a :href="file.url">
                            {{file.title}}
                        </a>
                    </li>
                </ul>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "TermCondition",
    props: {
        config: Array
    }
}
</script>

<style scoped>

</style>