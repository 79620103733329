import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        configuator: 'default',
        subtitle: '',
        products: {},
        productGroups: {}
    },
    mutations: {
        setConfiguator (state, configuator) {
            state.configuator = configuator;
        },
        setSubtitle (state, subtitle) {
            state.subtitle = subtitle;
        },
        addProductGroup (state, productGroup) {
            if (productGroup.id in state.productGroups) {
                throw "duplicate product group id " + productGroup.id;
            }
            state.productGroups[productGroup.id] = productGroup;
        },
        addProduct (state, product) {
            if (product.id in state.products) {
                throw "duplicate product id " + product.id;
            }
            state.products[product.id] = product;
        },
    },
    getters: {
        configuator: state => {
            return state.configuator;
        },
        subtitle: state => {
            return state.subtitle;
        },
        getProductGroup: (state) => (id) => {
            return state.productGroups[id];
        },
        getProduct: (state) => (id) => {
            return state.products[id];
        },
        getProductOptions: (state) => (productId) => {
            return state.products[productId].options;
        },
        getProductOption: (state) => (productId, optionId) => {
            return state.products[productId].options.find(item => optionId === item.id);
        },
        getProductOptionValue: (state) => (productId, optionId, valueId) => {
            const option =  state.products[productId].options.find(item => optionId === item.id);
            if (!('values' in option)) {
                return null;
            }
            return option.values.find(item => valueId === item.id);
        },
        getProductOptionValues: (state) => (productId, optionId) => {
            const option =  state.products[productId].options.find(item => optionId === item.id);
            if (!('values' in option)) {
                return [];
            }
            return option.values;
        }
    },
    actions: {
        runActions ({commit}, actions) {
            actions.forEach(action => {
                switch (action.name) {
                    case 'subtitle':
                        commit('setSubtitle', action.value);
                        break;
                    case 'configurator':
                        commit('setConfiguator', action.value);
                        break;
                }
            });
        }
    }
})
export default store;