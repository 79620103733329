<template>
    <label>
        {{ config.title }}
        <b-form-input v-model="text"></b-form-input>
    </label>
</template>

<script>
export default {
    name: "Textfield",
    props: {
        value: Array,
        config: Object,
    },
    data() {
        return {
            text: null
        }
    },
    watch: {
        text(newVal) {
            if (!this.text) {
                this.$emit('input', []);
            } else {
                this.$emit('input', [newVal]);
            }

        },
        value(newVal) {
            if (newVal.length > 0) {
                this.text = newVal[0];
            }
        }
    },
    created() {
        if (this.value.length > 0) {
            this.text = this.value[0];
        }
    }
}
</script>

<style scoped>

</style>