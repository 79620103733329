<template>
    <div>
        <label>
            {{config.title}}
            <b-form-checkbox-group
                v-model="valueModel"
                :options="selectOptions"
            ></b-form-checkbox-group>
        </label>
    </div>
</template>

<script>
export default {
    name: "Checkbox.vue",
    props: {
        config: Object,
        value: Array
    },
    computed: {
        valueModel: {
            get() { return this.value },
            set (value) { this.$emit('input', value); }
        },
        selectOptions() {
            return this.config.values.map((item) => {
                const pricePrefix = item.pricePrefix ? item.pricePrefix : '';
                return {
                    'value': item.id,
                    'text': item.price >= 0 ? item.label + ' ' + pricePrefix + this.$formatCurrency(item.price) : item.label + ' ' + pricePrefix
                }
            })
        }
    }
}
</script>

<style scoped>

</style>