<template>
    <label>
        {{ config.label }}<span v-if="config.required">*</span>
        <b-form-checkbox-group
            v-model="valueModel"
            :options="config.values"
        ></b-form-checkbox-group>
    </label>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        config: Object,
        value: Array
    },
    computed: {
        valueModel: {
            get() { return this.value },
            set (value) { this.$emit('input', value); }
        }
    }
}
</script>

<style scoped>

</style>