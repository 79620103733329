<template>
    <header>
        <b-container>
            <b-row align-v="center">
                <b-col cols="12" md="6">
                    <h1 class="h2 title">
                        {{ title }}
                    </h1>
                    <span class="subtitle h5">
                        {{ subtitle }} {{subtitle2}}
                    </span>
                </b-col>
                <b-col cols="12" md="6" class="justify-content-end">
                    <a :href="logoLink" :title="logoAlt" v-if="logoLink">
                        <b-img class="logo" :src="logo" right :alt="logoAlt"/>
                    </a>
                    <b-img v-else class="logo" :src="logo" right :alt="logoAlt"/>
                </b-col>
            </b-row>
        </b-container>
    </header>
</template>

<script>
    export default {
        name: "Header",
        props: {
            title: String,
            subtitle: String,
            logo: String,
            logoAlt: String,
            logoLink: String
        },
        computed: {
            subtitle2() {
                return this.$store.getters.subtitle;
            }
        }
    }
</script>

<style scoped>
    .title {
        margin: 0;
    }
    .subtitle {
        display: block;
        margin: 0;
    }
    header > .container {
        padding: 0;
    }

</style>