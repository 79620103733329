<template>
    <label>
        {{ config.label }}<span v-if="config.required">*</span>
        <b-form-radio-group
            v-model="valueModel"
            :options="config.values"
        ></b-form-radio-group>
    </label>
</template>

<script>
export default {
    name: "Radio",
    props: {
        config: Object,
        value: Array
    },
    computed: {
        valueModel: {
            get() { return this.value.length > 0 ? this.value[0] : '' },
            set (value) { this.$emit('input', [value]); }
        }
    }
}
</script>

<style scoped>

</style>