<template>
    <div>
        <component
            v-for="(subOption, index) in options"
            :key="'suboption-' + index"
            :is="getSubOptionComponent(subOption)"
            v-model="valueModel[index]['value']"
            :config="subOption"
            @input="onInput(subOption)"
        />
    </div>
</template>

<script>
import Dropdown from "./suboptions/Dropdown";
import Checkbox from "./suboptions/Checkbox";
import Radio from "./suboptions/Radio";
import Textfield from "./suboptions/Textfield";

export default {
    name: "SubOptionForm",
    components: { Dropdown, Checkbox, Radio, Textfield },
    props: {
        value: Array,
        productId: String,
        performActions: Boolean // this should be set to true, if the product is already selected
    },
    computed: {
        valueModel: {
            get() { return this.value; },
            set (value) { this.$emit('input', value); }
        },
        options() {
            return this.valueModel.map(optionData => this.$store.getters.getProductOption(this.productId, optionData.id));
        }
    },
    watch: {
        valueModel: {
            deep: true,
            handler() {
                if (this.performActions) {
                    this.valueModel.forEach(option => {
                        option.value.forEach(value => {
                            const valueObject = this.$store.getters.getProductOptionValue(this.productId, option.id, value);
                            if (valueObject && 'actions' in valueObject && valueObject['actions'].length > 0) {
                                this.$store.dispatch('runActions', valueObject['actions']);
                            }
                        });
                    });
                }
            }
        }
    },
    methods: {
        getSubOptionComponent(suboption) {
            switch (suboption.type) {
                case 'dropdown':
                    return 'Dropdown';
                case 'checkbox':
                    return 'Checkbox';
                case 'radio':
                    return 'Radio';
                case 'textfield':
                    return 'Textfield';
            }
            return '';
        },
        onInput() {
            this.$emit('input', this.valueModel);
        }
    }
}
</script>

<style scoped>

</style>